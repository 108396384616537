import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'

import { logout, selectUserData } from '../../app/features/user/userSlice'
import { startEngine } from '../../app/features/engine/engineSlice'

export default function WelcomeBack ({ fullScreenHandle }) {
  const router = useRouter()
  const dispatch = useDispatch()
  const user = useSelector(selectUserData)

  async function goToIntroVideo () {
    try {
      await fullScreenHandle.enter()
      await dispatch(startEngine())
    } catch (error) {
      // Ignore errors if we cannot enter fullscreen
      console.log(error)
    }

    await router.push('/')
  }

  return (
    <div className='center'>
      <div className='small box with-logo'>
        {user?.fullname && <p>Que bom te ver de novo, <strong>{user?.fullname}</strong>!</p>}
        {!user?.fullname && <p>Que bom te ver de novo!</p>}
        <button onClick={goToIntroVideo}>Entrar</button>
        <button onClick={() => dispatch(logout())}>Entrar com outra conta</button>
      </div>
    </div>
  )
}
